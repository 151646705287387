import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Wrapper from '../../components/Wrapper/Wrapper';
import Grid from '../../components/Grid/Grid';
import GuideCard from '../../components/Cards/Guide/GuideCard';
import { CartContext } from '../../components/CartProvider';
import SEO from '../../components/SEO';

const Guides = ({
  data: {
    allStripePrice: { edges: stripeEdges },
    allMarkdownRemark: { edges }
  }
}) => {
  // lookup table
  const prices = {};

  const { currency } = useContext(CartContext);

  for (const { node } of stripeEdges) {
    if (node.currency === currency) {
      prices[node.product.id] = node.unit_amount;
    }
  }

  return (
    <Wrapper>
      <SEO />
      <main id="container" className="container">
        <h1 className="title is-1 has-text-centered">Guides</h1>
        <Grid cols={1}>
          {edges.map(
            ({
              node: {
                id,
                excerpt,
                fields: { slug },
                frontmatter: {
                  title,
                  productId,
                  images: [first]
                }
              }
            }) => (
              <GuideCard
                key={id}
                image={first}
                title={title}
                excerpt={excerpt}
                link={slug}
                price={prices[productId]}
                currency={currency}
              />
            )
          )}
        </Grid>
      </main>
    </Wrapper>
  );
};
Guides.propTypes = {
  data: PropTypes.shape({
    allStripePrice: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
    }).isRequired,
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
    }).isRequired
  }).isRequired
};

export default Guides;

export const query = graphql`
  query GuidesQuery {
    allStripePrice(filter: { active: { eq: true } }) {
      edges {
        node {
          unit_amount
          currency
          product {
            id
          }
        }
      }
    }
    allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "GuidePage" } } }) {
      edges {
        node {
          id
          excerpt(pruneLength: 280)
          fields {
            slug
          }
          frontmatter {
            title
            productId
            pdf {
              publicURL
            }
            images {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 224)
              }
            }
          }
        }
      }
    }
  }
`;
