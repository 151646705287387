import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { imgFormatting, imgWidth } from './GuideCard.module.scss';
import { fmtPrice } from '../../../utils';
import AnimatedCardWrapper from '../../utils/AnimatedCardWrapper';

const GuideCard = ({ image, title, excerpt, link, price, currency }) => (
  <Link to={link} aria-label="Link to guide">
    <AnimatedCardWrapper className="tile is-child box px-0 columns is-vcentered">
      <div className="column is-narrow">
        <div className={imgWidth}>
          <figure className="image">
            <GatsbyImage
              image={getImage(image)}
              className={imgFormatting}
              imgClassName={imgFormatting}
              objectFit="contain"
              alt=""
            />
          </figure>
        </div>
      </div>
      <div className="column">
        <h2 className="title is-2">{title}</h2>
        <p className="content">{excerpt}</p>
      </div>
      <div className="column is-3">
        {price > 0 ? (
          <p className="subtitle is-4 has-text-centered is-italic">{fmtPrice(currency, price)}</p>
        ) : (
          <p className="title is-2 has-text-centered has-text-success is-italic">Free</p>
        )}
      </div>
    </AnimatedCardWrapper>
  </Link>
);

GuideCard.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired
};

export default GuideCard;
